<template>
  <div
    class="'text-center'"
    :class="{'q-py-md': $q.screen.lt.sm}"
  >
    <div class="text-center">
      <q-btn
        color="secondary"
        label="Create Card!"
      />
    </div>
  </div>
</template>
<script setup>
</script>
<style lang="sass" scoped>
</style>
